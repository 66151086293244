import revive_payload_client_CK9TrcQ4Pr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_better-sqlite3@11.3.0_drizzle-orm@0.34.1_svvcdf5y33ysxvwvt33gd24uv4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mu4OovM3PC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_better-sqlite3@11.3.0_drizzle-orm@0.34.1_svvcdf5y33ysxvwvt33gd24uv4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6Km7ZCeMyU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_better-sqlite3@11.3.0_drizzle-orm@0.34.1_svvcdf5y33ysxvwvt33gd24uv4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wp3lUVRwE6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_better-sqlite3@11.3.0_drizzle-orm@0.34.1_svvcdf5y33ysxvwvt33gd24uv4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ScYWh9AcwZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_better-sqlite3@11.3.0_drizzle-orm@0.34.1_svvcdf5y33ysxvwvt33gd24uv4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_23veHWtUE0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_better-sqlite3@11.3.0_drizzle-orm@0.34.1_svvcdf5y33ysxvwvt33gd24uv4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_gdUrk72FsB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_better-sqlite3@11.3.0_drizzle-orm@0.34.1_svvcdf5y33ysxvwvt33gd24uv4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_syfsiinT94 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_better-sqlite3@11.3.0_drizzle-orm@0.34.1_svvcdf5y33ysxvwvt33gd24uv4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_LEDRmpDYLw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-echarts@0.2.3_echarts@5.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_ZBB5PJ8f7u from "/opt/buildhome/repo/node_modules/.pnpm/@primevue+nuxt-module@4.1.0_@babel+parser@7.25.7_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_webpack-sources@3.2.3/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import sentryClientPlugin_uL6qP3brrI from "/opt/buildhome/repo/plugins/sentryClientPlugin.ts";
export default [
  revive_payload_client_CK9TrcQ4Pr,
  unhead_mu4OovM3PC,
  router_6Km7ZCeMyU,
  payload_client_wp3lUVRwE6,
  navigation_repaint_client_ScYWh9AcwZ,
  check_outdated_build_client_23veHWtUE0,
  chunk_reload_client_gdUrk72FsB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_syfsiinT94,
  plugin_LEDRmpDYLw,
  primevue_plugin_egKpok8Auk,
  plugin_client_ZBB5PJ8f7u,
  sentryClientPlugin_uL6qP3brrI
]